import React, { ChangeEvent, useState } from "react";
import { Alert, Button, Row } from "react-bootstrap";
import "./index.scss";
import CustomInput from "../../components/input";
import PasswordInput from "../../components/passaword_input";
import { Link, useNavigate } from "react-router-dom";
import AuthService from '../../services/auth.service';
import { AxiosError } from "axios";
import Loading from "../../components/loading";

export const Login = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] =  useState<string>('');
    const [erroLoginShow, seterroLoginShow] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };

    const handleLogin = async () => {
        setIsLoading(true);
        try {
          const response = await AuthService.login(
            email, 
            password
        );
          if (response.data) {
            localStorage.setItem('user_data', JSON.stringify(response.data));
            navigate('/');
          }
        } catch (error) {
            if (error instanceof AxiosError) {
                // Erro com resposta do servidor
                setError(`Erro ao fazer login: ${JSON.stringify(error.response?.data.message, null, 2)}`);
                seterroLoginShow(true);
            } else {
                // Outro erro
                setError(`Erro ao fazer login: ${String((error as Error).message)}`);
                seterroLoginShow(true);
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            handleLogin();
        }
    };
    
    return (
        
        <Row className="container_panel_flex_center">
            {isLoading && (
                <Loading /> 
            )}

            <div className="container_login_panel" onKeyDown={handleKeyDown} tabIndex={0}>
                <img src="/image/neurosia.jpeg" alt="Logo Neurosia"></img>
                <div className="container_login_panel_input">
                    <CustomInput
                        label="Nome"
                        type="text"
                        placeholder="Digite seu e-mail"
                        value={email}
                        onChange={handleInputChange}
                    />
                    <div className="container_login_panel_password"> 
                        <PasswordInput
                            label="Senha"
                            placeholder="Digite sua senha"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                        <Link to="/esquecisenha">Esqueci minha senha</Link>
                    </div>
                </div>
                <div className="container_login_panel_submit">
                    <Button variant="primary" onClick={handleLogin}>
                        Acessar
                    </Button>
                </div>
                {error && error.trim().length > 0 && (
                    <Alert variant="danger" onClose={() => seterroLoginShow(false)} dismissible show={erroLoginShow}>
                        <p>{error}</p>
                    </Alert>
                )}
            </div>
        </Row>

    );
}

